// .home{
//     display: flex;
    
//     .homeContainer{ 
//       flex: 8;
//       width:100vw;
//       overflow: hidden ;
//       background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
//       background-repeat: no-repeat;
//       //background-size:auto;
//       background-position:bottom right;
//       background-blend-mode:soft-light;

       .mainContainer{
         display: flex;
         flex-direction: column;
         align-items: center;         
         justify-content:space-evenly;
         margin: 0 auto;
         overflow: auto;
         background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
         background-repeat: no-repeat;       //background-size:auto;
         background-position:bottom right;
         background-blend-mode:soft-light;
         height:99vh;
         width:100hw;
         //position: relative;

         .padding_5{
            padding: 4px;
         }
      
         .title{
            //-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            display: flex;
            align-items: center;
            height: 50vh;

            .gradientText{

               // width:50%; 
               // height: 50%; 
               display: flex; 
               // margin-left: 20ch;
               // justify-self: center;
                align-self: center;
               font-size: 30px;
               font-weight: 800;
               font-family: Arial, Helvetica, sans-serif;
         
               background-image: linear-gradient(180deg, red, blue);
               background-clip: text;
               color: transparent;
               transition: width 5s, height 2s;

            }

         }


       }  
//     }

// }
// .borders{

//    -webkit-box-shadow: 6px 4px 5px 2px rgba(144, 124, 124, 0.775);
//    box-shadow: 2px 2px 3px 5px rgba(158, 146, 146, 0.939);
//    margin-left: 2px;
//    background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgb(244, 251, 244), rgba(220, 240, 223, 0.823)), ;
//    background-repeat: no-repeat;
//    //background-size: cover;
//    background-position: bottom right;
//    background-blend-mode: soft-light;
//  }