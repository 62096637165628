
@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
//@import "variables.scss";
@import 'mixins.scss';
// @import "bulma/sass/utilities/_all.sass";
// @import "bulma/sass/grid/columns.sass";
// @import "react-table-v6/react-table"; // react-table.css file
// @import "react-datepicker/dist/react-datepicker"; // react-datepicker.css file

 * {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body {
	width: 100%;
	background-color: #f7f7f7;
}

h1,
h2,
h3,
h4,
h5,
a,
button,
p,
span,
div,
ul,
ol,
li,
input,
select {
	font-family: cursive;
	//color: $text;
}

.button {
	outline: 0;
	border-radius: 4px;
	padding: 10px 14px;
	font-weight: 600;
	background-color: green;  //$green;
	transition: 0.3s ease;
	color: white;
	cursor: pointer;
	border: 1px solid green; //$green;
	@include font-size(14px);

	&.alt {
		background-color: #fff;
		color: #363636;
		border: 1px solid #dbdbdb;

		&:hover {
			opacity: 0.5;
		}
	}

	&.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		color: green; // $green;
		background-color: white;
		border: unset;
		padding: 10px 4px;
		
		> svg {
			width: 20px;
			height: 20px;
		}

		&:hover {
			opacity: 0.5;
		}
	}
}

.button-link {
	outline: 0;
	border: 0;
	font-weight: 600;
	transition: 0.3s ease;
	color: green; //$green;
	background-color: transparent;
	cursor: pointer;
	margin-top: 1px;
	border-bottom: 1px solid transparent;
	//@include font-size(14px);
	&:hover {
		opacity: 0.5;
	}
}

.button:hover {
	opacity: 0.8;
}

.button:disabled {
	cursor: not-allowed;
	background-color: #ddd;
	border-color: #ddd;
	color: rgba(0, 0, 0, 0.4);

	&:hover {
		opacity: 1;
	}
}

.button-small {
	background-color: green; //$green;
	border: 1px solid red; //$green;
	outline: 0;
    width:25;
    height:25;
	border-radius: 50%;
	padding: 4px 8px;
	font-weight: 600;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4=');
	transition: 0.3s ease;
	color: white;
	cursor: pointer;
}

.input {
	outline: 0;
	border: 1px solid #cbd5e0;
	border-radius: 4px;
	padding: 10px 14px;
	width: 100%;
	//@include font-size(14px);
	background-color: white;

	&:disabled {
		background-color: #f5f5f5;
		box-shadow: none;
		color: #7a7a7a;
	}

	&.full-width {
		width: 100%;
	}
}

.input::placeholder {
	color: #a0aec0;
}

.select {
	outline: 0;//2px solid red;
	display: block;
	border-radius: 4px;
	padding: 10px 28px 20px 14px;
	border: 1px solid #cbd5e0;
    user-select:auto;
	appearance: none;
	background-color: white;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4=');
	background-repeat: no-repeat;
	background-position: right 0px center;
	background-size: 23;
	@include font-size(14px);
	&.full-width {
		width: 100%;
	}
}

.section {
	padding: 12px;
}

.container {
	padding: 24px 32px;
	@include mq(mobile) {
		padding: 16px;
	}
}

h2.head-title {
	font-size: 22px;
	font-weight: 400;
	margin-bottom: 22px;
}

p.head-title {
	@include font-size(16px);
}

p.sub-title {
	font-weight: 600;
	margin-bottom: 4px;
	@include font-size(14px);
}

.full-width {
	width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// input[type=number] {
// //   -moz-appearance: textfield;
// }

@import "components/header.scss";
// @import "components/table.scss";
// @import "components/datepicker.scss";
// @import "components/navbar.scss";
// @import "components/nprogress.scss";
 @import "components/modal.scss";
// @import "components/dropzone.scss";
// @import "components/sweetalert.scss";
// @import "components/loader.scss";
// @import "pages/404.scss";
// @import "pages/dashboard.scss";
// @import "pages/login-register.scss";
// @import "pages/create-job.scss";
// @import "pages/locations.scss";
// @import "pages/products.scss";
// @import "pages/jobs.scss";
// @import "pages/manage-users.scss";
