.row {
  display: flex;
  // flex:3;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 20%;
  // height:100%;
  // font-size: 1.1em;
  // gap:10;
  // margin-bottom: 20;
  // min-height: 2.5em;
  // margin:100;
  padding: 1px;
}

.borders {
  -webkit-box-shadow: 6px 4px 5px 2px rgba(144, 124, 124, 0.775);
  box-shadow: 2px 2px 3px 5px rgba(158, 146, 146, 0.939);
  margin-left: 2px;
}

.icon {
  color: darkblue; // font-weight:800;
  cursor: pointer;
  &:hover {
    color: red;
  }
}

.gradient-text {
  //background-image: linear-gradient(5deg, blue,red, blue, green, yellow, orange);
  background-clip: text;
  color: transparent;
  text-shadow: 2px 3px 3px rgba(229, 119, 119, 0.873);
}

.gradient-text::first-letter {
  -webkit-initial-letter: 1.05;
  initial-letter: 1.02;
} // .back{ //background-image: linear-gradient(90deg, rgba(255, 0, 0, 0.01), rgba(255, 166, 0, 0.005), rgba(255, 255, 0, 0.005), rgba(0, 128, 0, 0.005), rgba(76, 0, 130, 0.002), rgba(238, 130, 238, 0.001));   }

.back1 {
  //background-image: linear-gradient(95deg, rgba(255, 0, 0, 0.1), rgba(255, 166, 0, 0.1), rgba(255, 255, 0, 0.15), rgba(0, 128, 0, 0.1), rgba(76, 0, 130, 0.1), rgba(238, 130, 238, 0.09));
  background-image: url("../resource/images/autom8-logo.png"),
    linear-gradient(
      to bottom,
      rgba(244, 251, 244, 1),
      rgba(220, 240, 223, 0.93)
    );
  background-repeat: no-repeat; //background-size:auto;
  background-position: bottom right; //background-color: rgba(214, 238, 230, 0.824);
  background-blend-mode: lighten; //opacity:85%;
}
.cell {
  font-size: smaller;
}
.accent {
  accent-color: red;
}
.img1 {
  width: 30px;
  height: 22px;
}
.img2 {
  // idth:5w0px;
  height: 30px;
}
.imgWide {
  width: 80px;
  height: 40px;
}

.input {
  width: 60ch;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: blue;
  padding-left: 20px;
  

}
