.single{
    display: flex;

    .singleContainer{
        flex: 8;
        // align-items: center;
        // justify-content: space-between;
        //height:116.5ch;
        background-image:  url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
        background-repeat: no-repeat;
        //background-size: cover;
        background-position:bottom right;
        background-blend-mode:soft-light;

        -webkit-box-shadow: 6px 4px 5px 2px rgba(144, 124, 124, 0.775);
        box-shadow: 3px 3px 5px 1px rgba(158, 146, 146, 0.939);
        margin-left:3px;
        // border: 5px solid red;
        
        .singleWrapper{
            display: flex;
            flex-direction: column;
            padding-top: 25px;
            //padding-top: 15px;
            //min-width:75%;
            //height:95%;
            align-items:center;
            justify-content:flex-start;
            //background:  linear- (0.25turn, #3f87a622, #ebf8e126, #f69c3c1d);
            border: 7px solid red;
            
            .delete{
                font-weight: 800;        
                color:rgba(255, 0, 0, 0.639);
                padding-bottom: 20px;
                //background:  linear-gradient(0.25turn, #3f87a622, #ebf8e126, #f69c3c1d);
                //padding: 2px;
            }

            .singleCustomer{
                font-weight: 800;
                color:rgba(215, 41, 41, 0.44);
            }

            .singleClient{
                font-weight: 800;
                color:blue;
            }
            .singleJob{
                font-weight: 800;
                color: green;
            }
            .singleUser{
                font-weight: 800;
                color:gray;
            }
            .singleSite{
                font-weight: 600;
                color:red;
            }
            .singleUnknown{
                font-weight: 100;
                color:black;
            }
            .Btn {
                width:12em;
                box-shadow: 6px 4px 8px rgba(0, 0, 0, 0.315);
                background-image: linear-gradient(115deg, #b8909b96 40%, #db8f6671);
                border-radius: 5px;
                cursor: pointer;
                
            }
                .Btn:active {
                    box-shadow: 5px 5px 7px 5px #5b625972;;
            }

            .title{
                display: flex;
                //flex-direction: row;
                // align-items:flex-end;
                // justify-content:center;
                //border: '10px solid red';
                //margin: 1px;

                .key{
                    display:flex;
                    flex-direction: column;
                    //flex:5;
                    -webkit-box-shadow: 3px 2px 2px 2px rgba(0, 0, 0, 0.17);
                    box-shadow: 3px 2px 2px 2px rgba(0, 0, 0, 0.17);
                    font-size: 18px;
                    width:50ch;
                    //height: auto;
                    align-items:flex-start;
                    // justify-content:center;
                }
                .value{
                    display:flex;
                    flex-direction: column;
                    //flex:5;
                    -webkit-box-shadow: 3px 2px 2px 2px rgba(0, 0, 0, 0.17);
                    box-shadow: 3px 2px 2px 1px rgba(0, 0, 0, 0.17);
                    font-size: 18px;
                    width:65ch;
                    align-items:flex-start;
                    //justify-content:center;
                }
            }   
        }
            
    }
}