.header-container {
	margin: 0 12px 12px 12px;
	border-bottom: 3px solid #ddd;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 12px 0;

	.page-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-shrink: 0;
		> h1.page-title {
			font-size: 30px;
			text-transform: capitalize;
			white-space: nowrap;
		}
	}

	.page-action {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		.button {
			flex-shrink: 0;
		}

		.input {
			width: 240px;
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBmaWxsPSIjY2JkNWUwIiBkPSJNMjcgMjQuNTdsLTUuNjQ3LTUuNjQ4YTguODk1IDguODk1IDAgMCAwIDEuNTIyLTQuOTg0QzIyLjg3NSA5LjAxIDE4Ljg2NyA1IDEzLjkzOCA1IDkuMDEgNSA1IDkuMDEgNSAxMy45MzhjMCA0LjkyOSA0LjAxIDguOTM4IDguOTM4IDguOTM4YTguODg3IDguODg3IDAgMCAwIDQuOTg0LTEuNTIyTDI0LjU2OCAyNyAyNyAyNC41N3ptLTEzLjA2Mi00LjQ0NWE2LjE5NCA2LjE5NCAwIDAgMS02LjE4OC02LjE4OCA2LjE5NSA2LjE5NSAwIDAgMSA2LjE4OC02LjE4OCA2LjE5NSA2LjE5NSAwIDAgMSA2LjE4OCA2LjE4OCA2LjE5NSA2LjE5NSAwIDAgMS02LjE4OCA2LjE4OHoiLz48L3N2Zz4=");
			background-repeat: no-repeat;
			background-size: 20px;
			background-position: 10px center;
			padding-left: 34px;
		}
	}

	@include mq(mobile) {
		.page-header {
			margin-bottom: 8px;
		}

		.page-action {
			width: 100%;
			justify-content: flex-start;

			.button, .select {
				margin-bottom: 12px;
			}

			.input {
				width: 100%;
			}
		}
	}
}