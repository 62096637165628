// .homeA{
//     display: flex;     // flex-direction:column;    // //background-color: rgb(235, 227, 227);

    .homeContainerA{
        //flex: 8;
        // align-items: center;
        // justify-content: space-between;
        width:100hw;
        height:100vh;
        overflow: auto ;
        background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
        background-repeat: no-repeat;
        //background-size:auto;
        background-position:bottom right;
        background-blend-mode:soft-light;

        .mainContainerA{
            //display: flex;
            //flex-direction:column;
            height:100vh;
            //overflow:auto;
            //margin: .5ch;
            // width:100%;        
            // -webkit-box-shadow: 6px 4px 5px 2px rgba(144, 124, 124, 0.775);
            // box-shadow: 3px 3px 5px 2px rgba(158, 146, 146, 0.939);
            // background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95));
            // background-repeat: no-repeat;
            // //background-size: cover;
            // background-position: bottom right;
            // background-blend-mode: soft-light;
            // //align-items: center;
            // justify-content: flex-start;
            overflow:auto;
            //margin: .5ch;

            //margin-left: 3px;
            //margin-top: 1px;
            //position: relative;

            .titleA{
                padding-top: 30px;
                padding-left: 0px;

                .padding_5A{
                    padding: 3px;
                    font-size: 14;
                    font-weight: 600;        
                    //color: black;

                }
                // .padding_10A{
                //     padding-top: 10px;
                //     padding-bottom: 10px;
                //     font-size: 20;
                //     font-weight: 800;
                //     //font-style: italic;
                //     font-family: cursive;
                //     color: black;

                // }

  
            //    .gradientText{
            //       width:40%; 
            //       height: 30%;  
            //       justify-self: left;
            //       align-self: center;
            //       font-size: 24px;
            //       font-weight: 800;
            //       background-image: linear-gradient(120deg, red, blue);
            //       background-clip: text;
            //       color: transparent;
            //       transition: width 3s, height 2s;

            //    }
            //    .gradientText:hover {
            //       height:50%;
            //       width: 80%;
            //     }

            }

            .meter {
                /* Reset the default appearance for -webkit- only */
                //-webkit-appearance: none;
                width: 300px;
            }
                
            .meter::-webkit-meter-bar {
            background: #eee;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
            border-radius: 3px;
            }
        
        }
    
    }
// }