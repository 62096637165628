body.ReactModal__Body--open {
	overflow: hidden;
}

.custom-modal-portal {
	.custom-modal-overlay {
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		flex-direction: column;

		.custom-modal-content {
			width: 100%;
			max-width: 680px;
			max-height: calc(100vh - 40px);
			background-color: white;
			outline: none;
			border-radius: 6px;
			overflow: hidden;
			transform: scale(0.8);
			transition: 0.3s ease;
			display: flex;
			flex-direction: column;
			position: relative;
			&.small {
				max-width: 580px;
			}
			&.extra-small {
				max-width: 480px;
			}
			&.big {
				max-width: 860px;
			}

			.header {
				padding: 20px;
				border-bottom: 2px solid #ddd;
				display: flex;
				justify-content: space-between;
				align-items: center;
				//background-color: $green;
				flex-shrink: 0;
				position: relative;
				.title {
					font-size: 16px;
					color: white;
					min-height: 20px;
				}

				.close-btn {
					background-color: rgba(0, 0, 0, 0.1);
					border: none;
					border-radius: 290486px;
					cursor: pointer;
					pointer-events: auto;
					display: inline-block;
					flex-grow: 0;
					flex-shrink: 0;
					font-size: 0;
					height: 20px;
					max-height: 20px;
					max-width: 20px;
					min-height: 20px;
					min-width: 20px;
					outline: 0;
					position: relative;
					vertical-align: top;
					width: 20px;
					appearance: none;
					&:before, &:after {
						background-color: #fff;
						content: "";
						display: block;
						left: 50%;
						position: absolute;
						top: 50%;
						transform: translateX(-50%) translateY(-50%) rotate(45deg);
						transform-origin: center center;
					}

					&:before {
						height: 2px;
						width: 50%;
					}

					&:after {
						height: 50%;
						width: 2px;
					}

					&:hover {
						background-color: rgba(0, 0, 0, 0.2);
					}
				}
			}

			.content {
				padding: 20px;
				flex-grow: 1;
				flex-shrink: 1;
				overflow: auto;
				background-color: #fff;
				-webkit-overflow-scrolling: touch;
			}
			
			.footer {
				border-top: 2px solid #ddd;
				padding: 20px;
				background-color: #eee;
				flex-shrink: 0;
				position: relative;
			}
		}
	}

	.ReactModal__Overlay {
		opacity: 0;
		transition: opacity 250ms ease;
		will-change: opacity !important;
	}

	.ReactModal__Overlay--after-open {
		opacity: 1;
	}

	.ReactModal__Overlay--before-close {
		opacity: 0;
	}

	.ReactModal__Content {
		will-change: transform !important;
	}

	.ReactModal__Content--after-open {
		transform: scale(1) !important;
	}

	.ReactModal__Content--before-close {
		transform: scale(0.8) !important;
	}
}

.Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: papayawhip;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
  }