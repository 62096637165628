.stack{
    display: flex;

    // width: 100ch;
    //padding:9px;
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    //box-shadow: 2px 4px 10px 3px rgba(203, 193, 193, 0.58);
    //border:1px solid black;
    //margin: 4px;
    //left: 5px;
    //top: 10px;
    //height: 100vh;
    //opacity: 1;
    //background-color: rgba(240, 223, 201, 0.416);
}

.stack1{
    display: flex;

    // justify-content:space-evenly;
    // align-items: flex-start;
    //padding-left:5ch;
    //padding-top:4ch;
    // width: 117ch;
    // height: 10ch;
    // justify-content:center;
    // align-items:flex-end;

    // gap: 10ch;
    // border:10px solid red;
    //margin: 3px;
    //left: 5px;
    //top: 10px;
    
    //opacity: 1;
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 3px rgba(203, 193, 193, 0.58);
    //background-color: rgba(240, 223, 201, 0.416);
}

.stackButtons{
    display: flex;
    justify-content:center;
    align-items: center;
    //padding-left:5ch;
    //padding-top:4ch;
    // width: auto;
    // height:auto;
    //justify-content:center;
    //align-items:flex-end;
    gap: 10ch;
    //border:10px solid red;

}

.stack12{
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    // padding:10px;
    // width: 100ch;
    //border:1px solid black;
    //left: 5px;
    //top: 10px;
    //height: 100vh;
    //opacity: 1;
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 3px rgba(203, 193, 193, 0.58);
    // background-color: rgba(240, 223, 201, 0.416);
}

.onefive::first-letter {
    -webkit-initial-letter: 1;
    initial-letter: 1;
}

.Button1 {
    width:15ch;
    height:5ch;
    box-shadow: 4px 4px 5px 1px rgba(0, 0, 0, 0.315);
    color:darkblue;
    background-image: linear-gradient(115deg, #c6a0aaad 40%, #eba782ac);
    cursor: pointer;

    .Button1:active{ box-shadow: 5px 5px 7px 8px #3b403a72; }

}

.ButtonSmall {
    width:3ch;
    height:4ch;
    box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.315);
    font-family: Arial, Helvetica, sans-serif;
    color:darkblue;
    background-image: linear-gradient(115deg, #c6a0aaad 40%, #eba782ac);   
    // font-size:10;    // font-weight: 1000;
     border-radius: '15px';
    cursor: pointer;

    .Button1:active { box-shadow: 5px 5px 7px 8px #3b403a72}
}


.ButtonNote{
    font-size:10;
    font-Weight:800;
    color: black; 
    border-radius:5px;
    width:11ch;
    // background: blueviolet;
    // z-index: 0;                                                
 
    :hover{
        //background-color: pink;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);

    }
}

.faultDescription{
    //max-height:60ch;    //height:35ch;
    font-family: Arial, Helvetica, sans-serif;
    overflow:'hidden';
    width:98.5ch;
    margin-top: 0ch;
    margin-left: 0ch;
    display: flex;
    flex-direction: column;    //justify-content: space-between;    // box-shadow: 1px 1px 2px 1px gray;    //  borderRadius:'7px';    //height: 100%;
    flex: 1;
    overflow-y: auto;    //width: 100%;
}
.avatar{
    width: 2.2em;
    height: 2.5em;
    border-radius: 50%;
    cursor: pointer;
}

.border{
    display: flex;
    justify-content:center;
    align-items:center;     // height: 85ch;    //padding:1ch;    //width: 100%;    -webkit-box-shadow: 0px 0px 2px 2px rgba(65, 92, 62, 0.388);
    box-shadow: 0px 0px 2px 2px rgba(65, 92, 62, 0.388);
}

.addressBorder{
    box-sizing: border-box;    
    width:600; //`${size === undefined || size===null ? '700px': `${size}`} `,
    height:5ch;
    //padding:0 10;
    border-radius:5px;
    width: 120ch;
    //box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
    // background:transparent;
    //outline:none;
    //text-overflow: 'ellipses';
    //position: absolute;
    //left: `${origin === undefined ? '21%': '45%'} `,
    margin-left:3ch;

    :hover{
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3);
        //border:2px solid gray;
    }



}



//.container{
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);

    // box-shadow: 0 0 2px 2px gray;
    // display: flex;
    // flex-direction: column;
    // //padding: 0 auto;
    // justify-content: center;
    //align-items: center;
    //height: 100%;
    //width:auto;
    //border: 12px solid blue;
    //margin-top: 10;
    //padding:10;
    //margin-left: 5ch;

// }








// .pic{
    // background-image:  url('../../resource/images/autom8-logo-white.png'), linear-gradient(to bottom, rgba(91, 127, 97, 0.474), rgba(150, 150, 96, 0.251)), ;
    // background-repeat: no-repeat;
    // //background-size: cover;
    // //background-position: bottom right;
    // //background-blend-mode:soft-light;
    // //background-size:contain;
    // background-position:center center;
    // //background-color: rgba(214, 238, 230, 0.824);
    // background-blend-mode:soft-light;

    // background-image:  url('../../resource/images/autom8-logo-white.png'),  linear-gradient(to bottom, rgba(91, 127, 97, 0.474), rgba(150, 150, 96, 0.251)), ;
    // background-repeat: no-repeat;
    // background-size:auto;
    // background-position:top right;
    // background-blend-mode:soft-light;
//}
