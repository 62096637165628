.app{
    //display: flex;
    //box-sizing: border-box;
    //margin: 10;
    //padding: 1;
    width:100%;
    height: 100vh;

    .appContainer{        
        display: flex;
        flex: 8;
    }
}