@mixin mq($breakpoint) {
    @if $breakpoint == "mobile" {
      @media (max-width: 768px){
        @content;
      }
    }
  
    @if $breakpoint == "desktop" {
      @media (min-width: 769px){
        @content;
      }
    }
  }
  
  @mixin font-size($size) {
    font-size: $size;
    @include mq(mobile) {
      font-size: $size + 2;
    }
  }