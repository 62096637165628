// .sideBar{
//     display: flex;    
    //flex-direction:row;   
    //background-color: rgb(235, 227, 227);

.navBar{
        // flex: 8;
        display:flex;
        align-items:flex-start;
        justify-content: space-between;    
        
        overflow: auto;
        background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
        background-repeat: no-repeat;
        background-size:auto;
        background-position:bottom right;
        background-blend-mode:soft-light;
        height:93vh;

        .aboutScr{    
            display: flex;
            flex-direction:column;
            align-items:center;
            justify-content: center;
            margin: 5ch;
            overflow:auto ;
            height: auto;
            padding:20px;

            .newItem{
                display: flex;
                flex-direction:column;
                align-items:center;
                justify-content: center;
                //margin: 10px;
                width:fit-content;
                
            }

            .aboutTable{
                 font-size:1em;

                // border:1px solid lightgray;
                 box-shadow: 0 0 2px 1px lightgray; 
                 
                
                .padding_10A{
                     padding-top: 10px;
                     padding-bottom: 15px;
                    font-size: 1.15em;
                    font-weight: 800;
                    //font-style: italic;
        
                    color: black;

                }


                .padding_5A{
                    padding: 10px;
                    font-size:1em;
                    font-weight: 400;
                    //color: black;
                }

                .hrA {
                    margin-left: 1px;
                    margin-bottom: 40px;
                    margin-top: 40px;
                    height: 7px;
                    width: 400px;
                    border: 4px solid rgb(198, 189, 189);
                    background-color: brown;
                }
  
                                                        //    .gradientText{
                                                        //       width:40%; 
                                                        //       height: 30%;  
                                                        //       justify-self: left;
                                                        //       align-self: center;
                                                        //       font-size: 24px;
                                                        //       font-weight: 800;
                                                        //       background-image: linear-gradient(120deg, red, blue);
                                                        //       background-clip: text;
                                                        //       color: transparent;
                                                        //       transition: width 3s, height 2s;

                                                        //    }
                                                        //    .gradientText:hover {
                                                        //       height:50%;
                                                        //       width: 80%;
                                                        //     }

            }


            meter {
                /* Reset the default appearance for -webkit- only */
                //-webkit-appearance: none;
                width: 300px;
              }
              
              meter::-webkit-meter-bar {
                background: #eee;
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
                border-radius: 3px;
              }
            
        
        }
    
}
// }