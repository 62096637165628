// .sideBar{
//     display: flex;

//     .navBar{
//         flex: 8;
//         // align-items: center;
//         // justify-content: space-between;
//         width:100hw;
//         overflow: hidden ;
//         background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
//         background-repeat: no-repeat;
//         //background-size:auto;
//         background-position:bottom right;
//         //background-blend-mode:soft-light;
//         background-blend-mode:lighten;  

        .profileScreen{            
            display:flex;
            align-items:flex-start;
            justify-content: space-between;    
            
            overflow: auto;
            background-image: url('../../resource/images/autom8-logo.png'), linear-gradient(to bottom,rgba(244, 251, 244, 1), rgba(220, 240, 223, .95)) ;
            background-repeat: no-repeat;
            background-size:auto;
            background-position:bottom right;
            background-blend-mode:soft-light;
            height:93vh;

                
            .itemTable{
                display: flex;
                flex-direction:column;
                align-items:center;
                justify-content: center;
                margin: 5ch;
                overflow:auto;
                height: auto;
                padding:20px;
                box-shadow: 0 0 1px 1px gray; 
                //width:fit-content;
                //width: 80%;                            
                //height: 40ch;
                 //margin-top: 5ch;
                // margin-left: 10px;
                //height: auto;
                //box-shadow: 0 0 3px 2px rgba(200, 191, 191, 0.939);                
                //overflow: hidden ;

                .table{
                    padding:1ch;
                    box-shadow: 0 0 2px 1px lightgray; 
                    //box-shadow: 0 0 3px 2px azure;
                    //border:1px solid black;                    
                }           

            }

        }

//     }    

// }
