.navbar{
    display: flex;
    //flex: 8;
    height: 2.9em;
    // border-top: 1px solid rgba(180, 168, 168, 0.924);
    // border-right: 1px solid rgba(180, 168, 168, 0.924);
    //  border: 1px solid navy;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow:  1px 1px 2px 1px gray;
    box-shadow: 1px 1px 1px gray;
    //font-size: .8em;
    //font-family: Arial, Helvetica, sans-serif;
    //color: #555;
    //width: 91vw;
    //position: sticky;
    //position: -webkit-sticky;
     //top: 10;
     //background-color:papayawhip;
     background-color:rgba(69, 117, 69, 0.623);
     overflow:auto;


    .wrapper{
        width:100%;
        //padding-left: 1px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        //font-family: Arial, Helvetica, sans-serif;
        //gap: 2em;

        .search{
            display: flex;
            align-items: center;
            //  border: 0.5px solid lightgray;
            padding: 3px; 
            input{
                border:none;
                outline: none;
                background-color: transparent;
                &::placeholder{ font-style:italic }
            }
        }

        .items{
            display: flex;
            align-items: center;
            gap: .5em;

            .item{
                display: flex;
                align-items: center;
                margin-right: 1.5em;
                position: relative;
                .icon{
                  font-size: 1em 
                }
                .avatar{
                  width: 2.6em;
                  height: 2.6em;
                  border: 1px solid #999;
                  border-radius: 5px;
                  cursor: pointer;
                }
                .counter {
                  width: 1.4em;
                  height: 1.4em;
                  background-color: red;
                  border-radius: 50%;
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  font-weight: bold;
                  position: absolute;
                  top: -0.4em;
                  right: -0.3em;
                }

                .clearfix::after {
                  content: "";
                  clear: both;
                  display: table;                
                } 
            }          
        }
        .logo {  
          width:"120ch";
          height: '10ch';          // display: flex;          // align-items: flex-start;          // justify-content: flex-start;
          font-size: .9rem;
          font-weight:600;          //font-family: Arial, Helvetica, sans-serif;
          color: #2d187be0;          //margin-left: 30px;        
        }
        .logo1 {  
          display: flex;
          align-items: flex-start;
          justify-self:center;
          font-size: 0.9rem;
          font-weight: bold;
          font-family:Arial, Helvetica, sans-serif;
          color: #ed2075e0;          //margin-left: 60px;
        }
    }

    .logo2{      
      //height: '10ch';
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin-right: 5;
      font-weight:600;
      font-size: 16;
      color: #2f1b74e0;        //  margin-left: 100px;        // max-height: '5ch';
      overflow: auto ;

    }
    .styled {
      border: 0;
      width:auto;
      line-height: 1.8;
      padding: 0 2px;
      font-family:Arial, Helvetica, sans-serif;
      font-size: .9rem;
      text-align: center;
      color: #fff;
      text-shadow: 1px 1px 1px #000;
      border-radius: 10px;
      background-color: rgba(220, 0, 0, 1);
      background-image: linear-gradient(to top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0));
      box-shadow: inset 2px 2px 3px rgba(255, 255, 255, 0.6), inset -2px -2px 3px rgba(0, 0, 0, 0.6);
      //cursor: pointer;
    }

    .styled1 {
      border: 0;
      width:max-content;
    }
    
    .styled:hover {
      background-color: rgba(255, 0, 0, 1);
    }
    
    .styled:active {
      box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6), inset 2px 2px 3px rgba(0, 0, 0, 0.6);
    }
}

// input[type=file]::file-selector-button {
//     border: 2px solid #6c5ce7;
//     content: 'Select some files';
//     padding: .2em .6em;
//     width:fit-content;
//     border-radius: .5em;
//     background-color: #a29bfe;
//     transition: 1s;
//     padding: 0 4px;
//     font-family:Arial, Helvetica, sans-serif;
//     cursor: pointer;
//   }
//   input[type=file]::file-selector-button:hover {
//     background-color: #eee;
//     border: 0px;
//     border-right: 1px solid #e5e5e5;
//   }
//   input[type=file]::file-selector-button:active{
//     background-color: #eee;
//     color:#540707;
//     box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6), inset 2px 2px 3px rgba(0, 0, 0, 0.6);
//   }
  .custom-file-input {
    color: transparent;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select File';
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    //-webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
    font-family:Arial, Helvetica, sans-serif;
  }
  .custom-file-input:hover::before {
    border-color: black;
    color: dodgerblue;
  }
  .custom-file-input:active {
    outline: 0;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #fedede, #f9f9f9); 
  }

  .avatar1{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    width: 3em;
    height: 2.5em;
    // font-size: 12;
    font-family:Arial, Helvetica, sans-serif;
    // font-weight: 800;
    background-color: rgb(246, 140, 158);
    border: 2px solid #2c0101;
    border-radius: 15%;
    cursor: pointer;
  }
  .avatar1::before{
    content:'' ///'🔗';

  }
  .avatar1::first-letter {
    font-size: 1.2rem;
    font-weight: bold;
    font-family:Arial, Helvetica, sans-serif;
    color: brown;
  }

  ::selection {
    color: gold;
    background-color: rgb(246, 92, 92);
  }

  ::placeholder {
    font-weight: bold;
    opacity: 0.3;
    color: rgb(93, 8, 8);
    font-family:Arial, Helvetica, sans-serif;
  }
  
