.nopage{
    display: flex;
    //background: pink;
    //min-height:auto;
    
    .nopageContainer{
        flex: 8;
        //align-items: center;

        .others{
            display: flex;
            -webkit-box-shadow: 6px 4px 5px 2px rgba(144, 124, 124, 0.775);
            box-shadow: 3px 3px 5px 1px rgba(158, 146, 146, 0.939);
            //justify-content:space-around;
            //align-items: center;
            //width:100%; 
            height:105vh;
            border: 1px solid gray;
            position: relative;

            justify-content:center;
            align-items:center;
            background: rgba(228, 185, 192, 0.856);
            //top:100;
            //margin-left: 300;
           .other1{
                display: flex;
                // width:50%;
                // height:55%;
                justify-content:center;
                align-items:center;
                //position: absolute;
                //top:100px;
                //left: 32px;
                //border: 1px solid black;
                color: red;
                font-size: 2em;
                font-weight: 600;
                //cursor:pointer
          }  
        }
    }
}